import React, { useState } from 'react';
import Image from 'next/legacy/image';

import { getImageDataWithHttps } from '../../../../../utils/contentful';
import { useScrollPosition } from '../../../../../hooks/useScrollPosition';

import CtaWidget from '../../../../CtaWidget';
import Link from '../../../../Link';
import { ProfileDropdownMenu } from '../../../ProfileDropdownMenu';

import { MobileNavProps } from '../index';

import {
  Hamburger,
  HamburgerContainer,
  LogoWrapper,
  MobileNavbarWrapper,
  StickyContainer
} from '../MobileNav.styles';

import mobileMenuOpenIcon from '@/images/hamburger-open.svg';
import mobileMenuCloseIcon from '@/images/hamburger-close.svg';
import defaultAbbreviatedLogo from '@/images/gl-logo-abbreviated.svg';
import defaultFullLogo from '@/images/gl-logo-full.svg';

interface Props extends MobileNavProps {
  isMenuOpen: boolean;
  toggleModal: () => void;
}

const defaultAbbreviatedLogoData = {
  ...defaultAbbreviatedLogo,
  alt: 'Greenlight logo'
};

const defaultFullLogoData = {
  ...defaultFullLogo,
  alt: 'Greenlight logo'
};

export function MobileNavbar({
  isSimplified,
  isScrolled,
  isMenuOpen,
  mobileCta,
  scrolledMobileCta,
  bottomMobileCta,
  abbreviatedLogoMobile,
  fullLogoMobile,
  linkToHome,
  toggleModal,
  showAuthenticatedUser
}: Props) {
  const [ctaScrollPosition, setCtaScrollPosition] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      if (bottomMobileCta) {
        if (currPos.y > 400) setCtaScrollPosition(true);
        else if (currPos.y <= 400) setCtaScrollPosition(false);
      }
    },
    [ctaScrollPosition, bottomMobileCta],
    undefined,
    true,
    100
  );

  const abbreviatedLogoData = abbreviatedLogoMobile
    ? getImageDataWithHttps(abbreviatedLogoMobile)
    : defaultAbbreviatedLogoData;

  const fullLogoData = fullLogoMobile
    ? getImageDataWithHttps(fullLogoMobile)
    : defaultFullLogoData;

  return (
    <>
      <MobileNavbarWrapper data-testid="mobile-nav-cta">
        <Link
          data-testid="mobile-logo-link"
          href={linkToHome ? linkToHome : '/'}
          className="flex"
        >
          {isScrolled && !showAuthenticatedUser ? (
            <LogoWrapper>
              <Image
                src={abbreviatedLogoData.src}
                alt={abbreviatedLogoData.alt}
                height={abbreviatedLogoData.height}
                width={abbreviatedLogoData.width}
              />
            </LogoWrapper>
          ) : (
            <Image
              src={fullLogoData.src}
              alt={fullLogoData.alt}
              height={fullLogoData.height}
              width={fullLogoData.width}
            />
          )}
        </Link>

        {isScrolled &&
          !isMenuOpen &&
          !mobileCta &&
          !showAuthenticatedUser &&
          !bottomMobileCta &&
          scrolledMobileCta && (
            <CtaWidget
              {...scrolledMobileCta?.fields}
              id="mobile-nav-cta"
              sysId={scrolledMobileCta?.sys?.contentType?.sys?.id}
              module="Navigation"
              isScrolled={isScrolled}
            />
          )}

        {!isSimplified && (
          <HamburgerContainer
            onClick={toggleModal}
            data-testid="hamburger-menu"
            data-blockid="open-hamburger"
          >
            <Hamburger>
              <Image
                src={isMenuOpen ? mobileMenuCloseIcon : mobileMenuOpenIcon}
                alt={isMenuOpen ? 'Close menu' : 'Open menu'}
              />
            </Hamburger>
          </HamburgerContainer>
        )}

        {mobileCta && !scrolledMobileCta && !showAuthenticatedUser && (
          <CtaWidget
            {...mobileCta?.fields}
            id="mobile-nav-cta"
            sysId={mobileCta?.sys?.contentType?.sys?.id}
            module="Navigation"
            isScrolled={isScrolled}
          />
        )}

        {showAuthenticatedUser && <ProfileDropdownMenu />}
      </MobileNavbarWrapper>

      {bottomMobileCta && ctaScrollPosition && !isMenuOpen && (
        <StickyContainer data-testid="bottom-cta">
          <CtaWidget
            {...bottomMobileCta?.fields}
            id="bottom-cta"
            errorPosition="top"
            module="Bottom CTA"
          />
        </StickyContainer>
      )}
    </>
  );
}
